import React from 'react'
import {graphql, Link, PageProps} from 'gatsby'
import Helmet from 'react-helmet'
import StandardLayout from '../layouts/standard'
import Section from '../components/section'
import Gallery from '../components/gallery'
import List from '../components/list'

const StillsPage = ({data, location}: PageProps<Queries.StillsPageQuery>) => {
	const stills = imagesFromData(/^STILL_/)
	const bts = imagesFromData(/^BTS_/)
	const lifestyle = imagesFromData(/^LIFESTYLE_/)

	return <StandardLayout location={location}>
		<Helmet>
			<body className="stills-page" />
		</Helmet>

		<h1 className="card-style-block-text">Galleries</h1>
		<nav className="page-nav">
			<List>
				<Link to="#stills">Stills</Link>
				<Link to="#lifestyle">Lifestyle</Link>
				<Link to="#bts">BTS</Link>
			</List>
		</nav>

		<hr />

		<Section title="Stills">
			<Gallery
				className="stills-gallery"
				// @ts-ignore: todo
				images={stills}
			/>
		</Section>

		<hr />
		
		<Section title="Lifestyle">
			<Gallery
				className="lifestyle-gallery"
				// @ts-ignore: todo
				images={lifestyle}
			/>
		</Section>

		<hr />

		<Section title="BTS">
			<Gallery
				className="bts-gallery"
				// @ts-ignore: todo
				images={bts}
			/>
		</Section>
	</StandardLayout>

	function imagesFromData(prefix: RegExp) {
		return Object.entries(data)
			.filter(([k]) => k.match(prefix))
			.map(([title, file]) => {
				const cleanTitle = title
					.replace(prefix, '')
					.replace(/[a-z][A-Z]/g, (s) => `${s[0]} ${s[1].toLowerCase()}`)
					.replace(/[a-z][A-Z]/g, (s) => s.toLowerCase())

				return {
					title: `${cleanTitle} (${file?.name})`,
					url: file?.publicURL,
					image: file?.childImageSharp?.gatsbyImageData,
				}
			})
	}
}

export default StillsPage

export const query = graphql`
	fragment ImageFile on File {
		name
		publicURL
		childImageSharp {
			gatsbyImageData(width: 700)
		}
	}

	query StillsPage {
		STILL_reel2realCU: file(sourceInstanceName: {eq: "src"}, name: {eq: "R2R4"}) { ...ImageFile }
		STILL_guilleFight: file(sourceInstanceName: {eq: "src"}, name: {eq: "LAW1"}) { ...ImageFile }
		STILL_gunsRaised: file(sourceInstanceName: {eq: "src"}, name: {eq: "HS8"}) { ...ImageFile }
		STILL_gretchenCU: file(sourceInstanceName: {eq: "src"}, name: {eq: "Gretchen2"}) { ...ImageFile }
		STILL_dontMakeMeShoot: file(sourceInstanceName: {eq: "src"}, name: {eq: "WFO2"}) { ...ImageFile }
		STILL_aLittleDark: file(sourceInstanceName: {eq: "src"}, name: {eq: "HLL7"}) { ...ImageFile }
		STILL_sadFather: file(sourceInstanceName: {eq: "src"}, name: {eq: "dodgson2"}) { ...ImageFile }
		STILL_cockyBusinessGuy: file(sourceInstanceName: {eq: "src"}, name: {eq: "R2R3"}) { ...ImageFile }
		STILL_doubtingSafety: file(sourceInstanceName: {eq: "src"}, name: {eq: "MAI3"}) { ...ImageFile }
		STILL_dominantLover: file(sourceInstanceName: {eq: "src"}, name: {eq: "Gretchen3"}) { ...ImageFile }
		STILL_sloppyDetective: file(sourceInstanceName: {eq: "src"}, name: {eq: "HS6"}) { ...ImageFile }
		STILL_abductedAndFucked: file(sourceInstanceName: {eq: "src"}, name: {eq: "LAW2"}) { ...ImageFile }
		STILL_funnyDetective: file(sourceInstanceName: {eq: "src"}, name: {eq: "HS3"}) { ...ImageFile }
		STILL_creepyGuy: file(sourceInstanceName: {eq: "src"}, name: {eq: "HLL6"}) { ...ImageFile }
		STILL_darkLover: file(sourceInstanceName: {eq: "src"}, name: {eq: "Gretchen1"}) { ...ImageFile }
		STILL_darkDetective: file(sourceInstanceName: {eq: "src"}, name: {eq: "RA1"}) { ...ImageFile }
		STILL_dontShoot: file(sourceInstanceName: {eq: "src"}, name: {eq: "WFO1"}) { ...ImageFile }
		STILL_sadFamily: file(sourceInstanceName: {eq: "src"}, name: {eq: "dodgson1"}) { ...ImageFile }
		STILL_smilingHomeless: file(sourceInstanceName: {eq: "src"}, name: {eq: "HS2"}) { ...ImageFile }
		STILL_dateSmile: file(sourceInstanceName: {eq: "src"}, name: {eq: "HLL2"}) { ...ImageFile }
		STILL_againstTheWall: file(sourceInstanceName: {eq: "src"}, name: {eq: "HS7"}) { ...ImageFile }
		STILL_nerdOrKiller: file(sourceInstanceName: {eq: "src"}, name: {eq: "CL4"}) { ...ImageFile }
        STILL_holidayHunk: file(sourceInstanceName: {eq: "src"}, name: {eq: "Xmas3"}) { ...ImageFile }
		STILL_dreamyBathrobe: file(sourceInstanceName: {eq: "src"}, name: {eq: "Flowers2"}) { ...ImageFile }
		STILL_resortGuy: file(sourceInstanceName: {eq: "src"}, name: {eq: "Flowers1"}) { ...ImageFile }
		STILL_smileyGuy: file(sourceInstanceName: {eq: "src"}, name: {eq: "Xmas1"}) { ...ImageFile }
		STILL_pioneerWithAGun: file(sourceInstanceName: {eq: "src"}, name: {eq: "ABE2"}) { ...ImageFile }
		STILL_thoughtfulGuy: file(sourceInstanceName: {eq: "src"}, name: {eq: "HLL4"}) { ...ImageFile }
		STILL_youngDetective: file(sourceInstanceName: {eq: "src"}, name: {eq: "REELARC1"}) { ...ImageFile }
		STILL_justALaugh: file(sourceInstanceName: {eq: "src"}, name: {eq: "Awakening1"}) { ...ImageFile }
		STILL_sailorSmile: file(sourceInstanceName: {eq: "src"}, name: {eq: "Theater__Sailor_in_Anything_Goes_"}) { ...ImageFile }

		BTS_Azquil2: file(sourceInstanceName: {eq: "src"}, name: {eq: "Azquil2"}) { ...ImageFile }
		BTS_Fight1: file(sourceInstanceName: {eq: "src"}, name: {eq: "Fight1"}) { ...ImageFile }
		BTS_Azquil3: file(sourceInstanceName: {eq: "src"}, name: {eq: "Azquil3"}) { ...ImageFile }
		BTS_SpaceCowboy1: file(sourceInstanceName: {eq: "src"}, name: {eq: "SpaceCowboy1"}) { ...ImageFile }
		BTS_BigNews1: file(sourceInstanceName: {eq: "src"}, name: {eq: "BigNews1"}) { ...ImageFile }
		BTS_Azquil1: file(sourceInstanceName: {eq: "src"}, name: {eq: "Azquil1"}) { ...ImageFile }
		BTS_Clarity1: file(sourceInstanceName: {eq: "src"}, name: {eq: "Clarity1"}) { ...ImageFile }
		BTS_BigNews2: file(sourceInstanceName: {eq: "src"}, name: {eq: "BigNews2"}) { ...ImageFile }
		BTS_Photoshoot2: file(sourceInstanceName: {eq: "src"}, name: {eq: "Photoshoot2"}) { ...ImageFile }
		
		LIFESTYLE_Bassin_It: file(sourceInstanceName: {eq: "src"}, name: {eq: "Bassin_It"}) { ...ImageFile }
		LIFESTYLE_BWSmile: file(sourceInstanceName: {eq: "src"}, name: {eq: "BWSmile"}) { ...ImageFile }
		LIFESTYLE_Climbing: file(sourceInstanceName: {eq: "src"}, name: {eq: "Climbing"}) { ...ImageFile }
		LIFESTYLE_MexicoHeart: file(sourceInstanceName: {eq: "src"}, name: {eq: "MexicoHeart"}) { ...ImageFile }
		LIFESTYLE_Mountaintop: file(sourceInstanceName: {eq: "src"}, name: {eq: "Mountaintop"}) { ...ImageFile }
		LIFESTYLE_MuayThaiKick: file(sourceInstanceName: {eq: "src"}, name: {eq: "MuayThaiKick"}) { ...ImageFile }
		LIFESTYLE_Music__bass: file(sourceInstanceName: {eq: "src"}, name: {eq: "Music__bass"}) { ...ImageFile }
		LIFESTYLE_Painting: file(sourceInstanceName: {eq: "src"}, name: {eq: "Painting"}) { ...ImageFile }
	}
`
